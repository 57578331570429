import { useEffect, useRef, useState } from "react";
import ValidationCodeInput from "../ValidationCodeInput";
import axios from "axios";
import config from "../../config";


const sleep = (ms) => new Promise((resolve, reject) => {
  setTimeout(() => resolve(ms), ms)
})

const ConfirmForm = ({ email }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [err, setErr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const firstInputRef = useRef();
  const clearInputsRef = useRef();


  useEffect(() => {
    focusFirst();
  },[])

  const clearInputs = () => {
    if(clearInputsRef.current !=null ){
      clearInputsRef.current(); //it should be a fn
    }
  }

  const onSuccess = () => {
    console.log('Verification successful. Your account will be deleted.');
    setShowModal(true);
  }

  const handleVerification = async () => {

    try {
      setErr(null);
      setIsLoading(true);
      const response = await axios.post(`${config.backend_url}/api/Account/ConfirmDeletion`, {
        email,
        code: verificationCode
      });

      const {responseCode, validationKey, responseText} = response.data; 
      console.log(response.data);
      setIsLoading(false);

      if(responseCode == "1") {
          //That's ok
          onSuccess();
      } else if (responseCode == "3") {
          //something goes wrong
          setErr(true);
          clearInputs();
          focusFirst();

      }

      
      
    } catch (err) {
      setErr(err);
      console.log(err);
      setIsLoading(false);
    }
  };

  const focusFirst = () => {
    if(firstInputRef.current != null){
      setTimeout(() => firstInputRef.current.focus(), 10);
    }
  }


  return (
    <>
      <div className="confirm-form">
        <p>
          To delete your account, please enter the verification code we sent to your email.
        </p>

        <div className="verification-form">
          <label htmlFor="verificationCode">Verification Code:</label>
          <ValidationCodeInput
            disabled={isLoading}
            onChange={setVerificationCode}
            firstInputRef={firstInputRef}
            clearInputsRef={clearInputsRef}
          />


          {err && <p className="text-center text-error">Invalid code. Please check your code and try again.</p>}

          <p className="note">
            <svg xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              className="nz sb bal">
              <path
                fillRule="evenodd"
                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                clipRule="evenodd">
              </path>
            </svg>

            By deleting your account, all personal data related to your KYC (Know Your Customer) process will be permanently removed from our records. Your KYC documents and information will no longer be stored or accessible.
          </p>

          <p>
            Additionally, please note that you can open an account with us at any time in the future if you wish to return. Any tokens you have purchased through the app will remain in the wallets you used for the transactions.
          </p>



          <div className="button-container">
            <button
              className="verification-button primary-button"
              onClick={handleVerification}
              disabled={isLoading}

            >
              {isLoading? <span className="loader-sm" /> : "Verify and Delete Account"}

            </button>
          </div>

        </div>
      </div>
      {showModal && (
        <div className="modal-backdrop">
          <div className="modal">
            <div className="modal-content">
              <p className="success-message">
                Your account validation is complete. Thank you for your request to delete your account.
                We are processing your request, and your personal data will be permanently removed as soon as possible.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmForm;