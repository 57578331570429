import './App.css';
import RequestDeletionForm from './components/deletion/RequestDeletionForm';
import ConfirmForm from './components/deletion/ConfirmForm';
import Header from './components/layout/header/header';
import { useState } from 'react';

function App() {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");

  return (
    <>
      <Header />
      <main>
        <h1 className="page-title">Account Deletion Request</h1>
        {step === 0 && <RequestDeletionForm onSuccess={(email) => {
          setEmail(email);
          setStep(1);
        }} />}
        {step === 1 && <ConfirmForm email={email} />}
        {/* Message to inform that your account has been delete successfully */}
        
      </main>
    </>

  );
}

export default App;
