import React, { useState, useRef, useEffect } from 'react';

import "./ValidationCodeInput.css";

const ValidationCodeInput = ({ onChange, disabled=false, firstInputRef, clearInputsRef }) => {
  const [codes, setCodes] = useState(['', '', '', '', '']);
  const inputRefs = [firstInputRef, useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    onChange(codes.join(""));
  }, [codes]);


  clearInputsRef.current = () => {
    setCodes(['', '', '', '', '']);
  }


  const handlePaste = (e,index) => {
    if(index === 0){
      e.preventDefault();
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('text');

      if(pastedText.length === 5){
        setCodes(pastedText.split(""));
        inputRefs[4].current.focus();
      }
    }
  }

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (/^\d+$/.test(value) && value.length === 1) {
      const newCodes = [...codes];
      newCodes[index] = value;
      setCodes(newCodes);

      if (index < 4) {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === '') {
      if (index > 0) {
        const newCodes = [...codes];
        newCodes[index] = value;
        setCodes(newCodes);
        inputRefs[index - 1].current.focus();
      } else if (index == 0) {
        setCodes(["", "", "", "", ""])
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      const newCodes = [...codes];
      newCodes[index] = '';
      setCodes(newCodes);
      e.preventDefault();
      return inputRefs[index - 1].current.focus();
    }

  }

  return (
    <div className="validation-code-input">
      {codes.map((code, index) => (
        <input
        className='no-spin'
          key={index}
          ref={inputRefs[index]}
          type="number"
          inputMode="numeric"
          maxLength={1}
          min={0}
          max={9}
          value={code}
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          onPaste={(e) => handlePaste(e,index)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default ValidationCodeInput;
