import "./header.scss";

const Header = () => {
    return (
        <header className="fmd-header">
            <img className="fmd-header-logo" src="/images/logo_row_white.png" alt="" />
        </header>
    )
}


export default Header;