import { useState } from "react";
import axios from "axios";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";

const sleep = (ms) => new Promise((resolve, reject) => {
    setTimeout(() => resolve(ms), ms)
})

const RequestDeletionForm = ({ onSuccess }) => {
    const [email, setEmail] = useState("");
    const [captcha, setCaptcha] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleRecaptchaChange = (value) => {
        setCaptcha(value);
        if (value) {
            console.log("captcha is OK");
            //can continue
            // reCAPTCHA verification passed
            // You can now proceed with your form submission or any other action
        } else {
            // Verification failed, you might want to show an error message
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            const response = await axios.post(`${config.backend_url}/api/Account/RequestDeletion`, { email, captcha }); 
            console.log(response.data);

            setIsLoading(false);

            console.log(email);
            onSuccess(email); //Don't call this line, before captcha was ok
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <p>Please enter your email to request account deletion.</p>

            <div className='form-container'>

                {isLoading ? (
                    <span className="loader" />
                ) :

                    <form className="deletion-form" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email">Email:</label>
                        </div>
                        <div className="input-container">
                            <input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                className="email-input custom-input"
                                type="email"
                                id="email"
                                name="email"
                                required
                            />
                            <div className="captcha-container">

                                <ReCAPTCHA
                                    sitekey="6Lf99ucoAAAAALkhWpPR4VPNjBKLxljw6B88FEtk"
                                    onChange={handleRecaptchaChange}
                                />
                            </div>

                            <button 
                                className="delete-account-button"
                                disabled={captcha == null} >Request Deletion</button>
                        </div>

                    </form>



                }


            </div>
            <p className="email-message">You will receive an email with a confirmation code for account deletion.</p>
        </>
    )
}

export default RequestDeletionForm;